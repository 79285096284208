"use client";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useContext, useEffect } from "react";
import { SearchBoxContext } from "~/components/search/searchBoxProvider";

interface ISearchUrlProps {
	searchTextQueryParam: string;
}

const SearchUrl: React.FC<ISearchUrlProps> = ({ searchTextQueryParam }) => {
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const { setSearchText, debouncedSearchText, setDebouncedSearchText } =
		useContext(SearchBoxContext);

	// Load the query params from the url when the page first loads
	const loadQueryParams = useCallback(() => {
		const searchTextQuery = searchParams.get(searchTextQueryParam) || "";
		setSearchText(searchTextQuery);
		setDebouncedSearchText(searchTextQuery);
	}, [searchParams, searchTextQueryParam, setDebouncedSearchText, setSearchText]);
	// -- Only want this initial API call to happen on page load, so use empty dependency array
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => loadQueryParams(), []);

	// Update the url when the search text changes
	useEffect(() => {
		const current = new URLSearchParams(Array.from(searchParams.entries()));
		const updatedText = debouncedSearchText.trim();
		current.set(searchTextQueryParam, updatedText);
		const updatedParamsString = current.toString();
		const updatedParams = updatedParamsString ? `?${updatedParamsString}` : "";
		router.push(`${pathname}${updatedParams}`, { scroll: false });
	}, [pathname, router, searchParams, debouncedSearchText, searchTextQueryParam]);

	return <></>;
};

export default SearchUrl;
