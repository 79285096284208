"use client";

import Image from "next/image";
import { PropsWithChildren, useContext, useState } from "react";
import { SearchBoxContext, SearchBoxProvider } from "~/components/search/searchBoxProvider";
import SearchUrl from "~/components/search/searchUrl";
import { getSiteImageFullPath } from "~/lib/siteMedia";

interface ISoftwareLandingPageLayoutPortionWithStateProps {
	healthGrid: React.ReactNode;
	search: React.ReactNode;
}

const SoftwareLandingPageLayoutPortionWithState: React.FC<
	PropsWithChildren<ISoftwareLandingPageLayoutPortionWithStateProps>
> = props => {
	const [searchText, setSearchText] = useState("");
	const [debouncedSearchText, setDebouncedSearchText] = useState("");
	return (
		<SearchBoxProvider
			debouncedSearchText={debouncedSearchText}
			searchText={searchText}
			setDebouncedSearchText={setDebouncedSearchText}
			setSearchText={setSearchText}
		>
			<SearchUrl searchTextQueryParam="searchText" />
			<SoftwareLandingPageLayoutPortionWithStateInner {...props} />
		</SearchBoxProvider>
	);
};
const SoftwareLandingPageLayoutPortionWithStateInner: React.FC<
	PropsWithChildren<ISoftwareLandingPageLayoutPortionWithStateProps>
> = ({ healthGrid, search, children }) => {
	const { debouncedSearchText } = useContext(SearchBoxContext);
	const hasSearchText = !!debouncedSearchText.trim();
	return (
		<>
			<div className="bg-bottom bg-software-top-background bg-cover md:bg-[length:1500px_240px] bg-no-repeat bg-[#351c5b]">
				<div className="max-w-screen-xl mx-auto">
					<div className="mx-4 mb-12 md:mx-8">{children}</div>
				</div>
			</div>
			{hasSearchText ? (
				<div
					className={`bg-top basis-1 grow shrink-0 bg-software-bottom-background bg-[length:1500px_800px] bg-no-repeat bg-[#351c5b]`}
				>
					<div className="max-w-screen-xl mx-auto">
						<div className="mx-4 mb-12 md:mx-8">{search}</div>
					</div>
				</div>
			) : (
				<>
					<div className="absolute hidden lg:block left-[calc(50%+389px)] top-[105px] w-[115px] h-[28px] z-10">
						<a
							href={`https://cosmos.epic.com`}
							id={`software_cosmos_link`}
							target="_blank"
							rel="noopener noreferrer"
							className="relative block w-full h-full cursor-pointer"
						>
							<Image
								alt=""
								src={getSiteImageFullPath("software/cosmos.png")}
								priority
								fill
								className="object-contain"
								sizes="115px"
							/>
						</a>
					</div>
					<div
						className={`bg-top basis-1 grow shrink-0 bg-software-bottom-background md:bg-software-glow-bottom-background lg:bg-software-lined-bottom-background bg-[length:2200px_800px] bg-no-repeat bg-[#351c5b] pb-10`}
					>
						<div className="max-w-[500px] md:max-w-[1000px] mx-auto">{healthGrid}</div>
					</div>
				</>
			)}
		</>
	);
};
export default SoftwareLandingPageLayoutPortionWithState;
